<template>
	<div id="app">
		<!--头部-->
		<!-- <nav-bar  :imgList="imgList"></nav-bar> -->
		<!--内容-->
		<!-- <router-view v-if="ifRouterAlive"/> -->
		<!--底部-->
		<!-- <tab-bar  :imgList="imgList"></tab-bar> -->
		<!-- <div >
			<nav-bar></nav-bar>
			<router-view />
			<tab-bar></tab-bar>
		</div> -->
		<div v-if="$route.meta.keepAlive">
					<nav-bar></nav-bar>
					<router-view />
					<tab-bar></tab-bar>
				</div>
				<div v-if="!$route.meta.keepAlive">
					<div><router-view /></div>
				</div>
	</div>
</template>

<script>
import NavBar from './components/common/NavBar';
import TabBar from './components/common/TabBar';
export default {
	name: 'App',
	data() {
		return {
			imgList: [],
			ifRouterAlive: true,
			flag: true
		};
	},
	created() {
		// this.Logo()
	},
	
	provide() {
		return {
			reload: this.reload //用于页面的刷新
		};
	},
	methods: {
		reload() {
			this.ifRouterAlive = false;
			this.$nextTick(() => {
				this.ifRouterAlive = true;
			});
		}
	},
	components: {
		TabBar,
		// HomeSwiper,
		NavBar
	}
};
</script>

<style>
@import 'assets/css/reset.css';
@import 'assets/css/public.css';

.oneline {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
	}

	.twolines {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}

	.threelines {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}
	.forelines {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 4;
		overflow: hidden;
	}
</style>
