import {
	get,
	post
} from "./http"



export const Send_Sms = p => get('api/login/send_sms', p); // 获取验证码
export const DLogin = p => get('api/login/goin', p); // 动态密码登陆
export const Login = p => get('api/login/goins', p); // 账号密码登陆
export const Register = p => get('api/login/register', p); //注册
export const Rlogin = p => get('api/login/rlogin', p); //忘记密码下一步
export const Rlogins = p => get('api/login/rlogins', p); //重置密码
export const init = p => get('index/Index/bind', p); // 连接后台消息

export const Logo = p => get('api/achievements/logo', p); //首页logo 
export const Info = p => get('api/login/userinfo', p); //用户信息
export const Bottom = p => get('api/bottom/bottom_list', p); //底部数据
export const AboutList = p => get('api/about/about_list', p); ///关于科汇
export const ContactList = p => get('api/about/contact_list', p); ///联系我们
export const AType = p => get('api/about/types', p); ///诚聘英才 分类
export const JoinList = p => get('api/join/join_list', p); ///诚聘英才
export const Leader = p => get('api/about/leader', p); ///领导关怀
export const EventList = p => get('api/about/event_list', p); ///科汇大事记


export const Data = p => post('api/ad/shujus', p); //其他页面数据
export const DataZC = p => post('api/ad/shujuzc', p); //地图的政策数据

// 科服云
export const Kefuyun = p => post('api/provider/kefuyun', p); // 科服云主页 
export const Addserver = p => get('api/server/server_add', p); // 服务发布 
export const Type = p => get('api/server/type', p); // 服务发布   -----  服务类型
export const Sdisplay = p => get('api/server/display', p); // 服务展示
export const SdisplayXQ = p => get('api/server/server_xq', p); // 服务展示详情
export const EmployerXq = p => get('api/provider/employer_xq', p); // 服务商详情
export const EmployerAdd = p => get('api/provider/employer_add', p); // 服务商入驻 
export const eType = p => get('api/provider/employer_type', p); // 服务商入驻  -----  服务商类型
export const eProducts = p => get('api/server/server_products', p); // 服务商详情页的服务产品
export const ArticleList = p => get('api/provider/article_list', p); // 科服云动态列表
export const ArticleXq = p => get('api/provider/article_xq', p); // 科服云动态列表详情
export const ManagerXq = p => get('api/manager/manager_xq', p); // 技术经理人详情


// 政府云 
export const PolicyType = p => get('api/policy/zcsi', p); // 政府融资Tab 
export const Financing = p => get('api/policy/financing', p); // 政府融资 
export const PolicyXq = p => get('api/policy/policy_xq', p); //政府
export const Zatype = p => get('api/activity/zatype', p); //任务与活动 Tab类型
export const Zactivity = p => get('api/activity/zactivity', p); //任务与活动 Tab内容 
export const inType = p => get('api/innovate/intype', p); //考核或评估 Tab类型 
export const InnovateList = p => get('api/innovate/innovate_list', p); //考核或评估 Tab内容 
export const InnovateXq = p => get('api/innovate/innovate_xq', p); //考核或评估 详情 
export const wandlist = p => get('api/wand/wand_list', p); // 国家技术
export const wandblist = p => get('api/wand/wandb_list', p); // 科技成果
export const wandzhong = p => get('api/wand/zhong', p); // 众创空间
export const wandfuhua = p => get('api/wand/fuhua', p); // 孵化器
export const wanddlist = p => get('api/wand/wandd_list', p); // 地图数据
export const wandalist = p => get('api/wand/wanda_list', p); // 科技服务
export const wandclist = p => get('api/wand/wandc_list', p); // 技术合同
export const wandkeyan = p => get('api/wand/keyan', p); // 研发投入情况
export const kjlist = p => get('api/zonghe/kj_list', p); // 科技成果占比
export const zonghe = p => get('api/zonghe/zonghe', p); // 专家人才库
export const zjlist = p => get('api/zonghe/zj_list', p); // 专家领域发布
export const qylists = p => get('api/zonghe/qy_lists', p); // 新增用户
export const chju = p => get('api/zonghe/chju', p); // 成果与技术
export const contractlist = p => get('api/zonghe/contractlist', p); // 技术合同登记
export const servicek = p => get('api/zonghe/servicek', p); // 服务资源库
export const jushuk = p => get('api/zonghe/jushuk', p); // 技术需求库
export const qylist = p => get('api/zonghe/qy_list', p); // 入驻企业分布


//智产云  
// export const Wisdom = p => post('api/server/servercloud', p); // 主页
export const NeedList = p => post('api/demand/demand_lists', p); // 更多需求
export const NeedXq = p => post('api/demand/demand_xq', p); //    需求详情
export const uDemand = p => get('api/demand/udemand', p); // 需求详情的我要接单

export const Wisdom = p => post('api/patent/patent_zhu', p); // 主页
export const PatentList = p => post('api/patent/patent_list', p); // 更多产权
export const PatentXQ = p => post('api/patent/panentxq', p); // 产权详情
export const PatentAdd = p => get('api/patent/patent_add', p); // 产权发布  
export const PType = p => get('api/patent/ptype', p); // 产权发布   ------   产权类型
export const Patents = p => get('api/patent/patents', p); // 产权发布   ------   专利类别
export const ApplyClass = p => get('api/patent/applyclass', p); // 产权发布分类

//企业云
export const Nums = p => post('api/provider/nums', p); // 资源大数据
export const Expert = p => post('api/expert/expert_search', p); // 人才猎寻 
export const ExpertXq = p => post('api/expert/dexpert_xq', p) // 人才猎寻 ---  专家详情
export const Hall = p => post('api/server/server_hall', p); // 需求大厅 
export const HallQ = p => post('api/server/server_demand', p); // 需求大厅  -- 企业
export const HallForm = p => post('api/server/server_contact', p); // 需求大厅 ---  提交
export const Message = p => post('api/message/message_add', p); //  需求大厅 ---在线咨询
export const DType = p => post('api/demand/type', p); // 返回需求类别 
export const DemandName = p => post('api/policy/demandname', p); // 返回需求名称
export const AchievementsLists = p => post('api/achievements/achievements_list', p); //更多成果  我发布的成果列表
export const AchievementsXq = p => post('api/achievements/achievements_xq', p); // 成果详情 
export const HZ = p => post('api/achievements/hzuo', p); // 寻求合作
export const HR = p => post('api/achievements/hren', p); // 寻求合作名单
export const ServerKnowledge = p => get('api/server/server_knowledge', p); //  综合服务专项类通用接口  ---  进入专题页面

//  会员中心 
export const DemandList = p => get('api/demand/demand_list', p); //我发起的需求列表
export const wDemandXq = p => get('api/demand/wdemand_xq', p); //我发起的需求详情
export const wDemand = p => get('api/demand/wdemand', p); //双方确定接单
export const Order = p => get('api/expert/server_order', p); //专家选择
export const Success = p => get('api/demand/success_end', p); //需求服务结束

export const Provider = p => get('api/provider/ppatent', p); // 评估报告


export const UDemandList = p => get('api/demand/udemand_list', p); //我参与的需求列表
export const RecruitList = p => get('api/recruit/recruit_list', p); //我发布的招聘列表
export const RecruitXq = p => get('api/recruit/recruit_xq', p); //我发布的招聘详情
export const achievementsLists = p => get('api/achievements/achievements_lists', p); //找技术
export const demandLists = p => get('api/demand/demand_lists', p); //找需求

export const PolicyList = p => get('api/policy/policy_list', p); //找政策
export const Fan = p => get('api/policy/fan', p); //找政策 政策速递 筛选
export const Library = p => get('api/policys/policys_list', p); //政策文库
export const ZCFL = p => get('api/policy/zcfl', p); //政策文库项目分类
export const PolicysXq = p => get('api/policys/policys_xq', p); //政策文库详情
export const ServerList = p => get('api/server/server_list', p); //找服务
export const ExpertList = p => get('api/expert/expert_list', p); //找人才（专家列表）
export const activityList = p => get('api/activity/activity_list', p); //科创活动
export const ActivityXq = p => get('api/activity/activity_xq', p); //科创活动详情
export const BMActivity = p => get('api/activity/activity_bao', p); //科创活动详情 --- 我要报名

export const AMessages = p => get('api/message/message_adds', p); //在线咨询
export const MessageList = p => get('api/message/message_lists', p); //在线咨询列表
export const demand = p => post('api/demand/title', p); // 返回需求领域
export const Territory = p => post('api/achievements/territory', p); // 返回成果领域
export const Urgency = p => post('api/demand/urgency', p); // 返回紧急程度
export const Ares = p => post('api/demand/area', p); // 返回地区
export const Maturity = p => post('api/achievements/maturity', p); // 返回技术成熟度
export const Hmethodad = p => post('api/achievements/hmethod', p); // 返回合作方式
export const Industry = p => post('api/recruit/industry', p); //返回行业领域
export const Degree = p => post('api/recruit/degree', p); //  返回学位
export const Education = p => post('api/expert/education', p); //返回学历
export const is_grant = p => post('api/demand/is_grant', p); //返回审核状态
export const fwzt = p => post('api/demand/service', p); //返回服务状态
export const demandSuccess = p => post('api/demand/demand_success', p); //成功案例列表


//  创新工具
export const LessonXq = p => post('api/lesson/lesson_xq', p); //课程详情

// 器械
export const qixieDetail = p => post('api/product/getDetail', p); //器械详情
export const yiqi_fenlie = p => post('api/product/getCategory', p); //仪器分类
export const research = p => post('api/product/getResearch', p); //仪器分类
export const yiqi_list = p => post('api/product/getList', p); //仪器列表
