import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    obj: {
      id: 1,
      index: 0,
    },
    list: {},
    PartyIndex: 1
  },
  mutations: {
    AlterCurrentIndex(state, payload) { //修改索引
      state.obj = payload
    },
    AlterList(state, payload) {
      state.list = payload
    },
    AlterParty(state, payload) {
      state.PartyIndex = payload
    }
  },
})
export default store