<template>
  <div class="nav-bar">
    <div class="nav-top">
      <p>
        <span @click="dian_index">科汇云首页</span
        ><span class="bod_colo">|</span>
        <span><i class="el-icon-phone"></i></span><span>咨询热线:</span
        ><span>{{phone}}</span>
      </p>
      <div class="top-ri">
        <div class="ri-le">
          <span><i class="el-icon-user"></i></span>
          <span>
            <p v-if="user">
              {{ user.phone }}
            </p>
            <p v-else @click="login">登录/注册</p>
          </span>
        </div>
        <div class="ri-le" v-show="user">
          <span>
            <p
              @mouseover="changeActive($event)"
              @mouseout="removeActive($event)"
              @click="changeActive($event)"
            >
              身份 <i class="el-icon-arrow-down"></i>
            </p>
          </span>
          <div
            class="identity"
            v-show="show"
            @mouseover="changeActive($event)"
            @mouseout="removeActive($event)"
          >
            <p @click="toExpert(info.expert)">
              {{ info.expert ? "专家" : "" }}
            </p>
            <p @click="toManager(info.manager)">
              {{ info.manager ? "技术经理人" : "" }}
            </p>
            <p @click="toProvider(info.provider.id)">
              {{ info.provider ? "服务商" : "" }}
            </p>
          </div>
        </div>
        <div class="ri-mid" @click="tui_chu">
          <p style="color: #3892eb">退出</p>
        </div>
        <div class="ri-ri" @click="toClick">
          <p>会员中心</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { init, Info,Logo } from "@/common/js/api";
export default {
  name: "Navbar",
  // props: ["imgList"],
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      new_title: "", //记录搜索字段
      nav_top: "", //顶部左边文字
      show: false,
      info: [],
      phone:''
    };
  },
  created() {
    // this.Top();
     this.getLogo();
    this.Info();
    if (this.user) {
      try {
        let ws = new WebSocket("ws://hout.kehui.cloud:7272"); // 服务端主动推送消息时会触发这里的onmessage

        this.socket.setWs(ws);
        ws.onopen = function () {
          console.log("websocket连接成功");
        };
        ws.onmessage = function (e) {
          // json数据转换成js对象
          var data = e.data;
          data = JSON.parse(data);
          // console.log(data);
          var type = data.type || "";
          switch (type) {
            case "init":
              init({
                client_id: data.client_id,
              }).then((res) => {
                console.log("绑定成功1111111");
              });

              break;

            default:
              this.$message({
                message: "恭喜你，这是一条成功消息",
                type: "success",
              });
          }
        };
      } catch (err) {
        console.log(err);
      }
    }
  },
  methods: {
    // 用户信息
    Info() {
      Info({
        id: this.user.id,
      })
        .then((res) => {
          console.log(res);
          this.info = res.data;
        })
        .catch(() => {});
    },
// 咨询热线
    getLogo() {
      Logo({})
        .then((res) => {
          this.phone = res.data.title;
        })
        .catch(() => {});
    },
    changeActive($event) {
      this.show = true;
    },
    removeActive($event) {
      this.show = false;
    },

    // 前往专家详情
    toExpert(id) {
      this.$router.push({
        path: "/expert",
        query: {
          id: id,
        },
      });
    },
    // 技术经理人详情
    toManager(id) {
      this.$router.push({
        path: "/manager",
        query: {
          id: id,
        },
      });
    },
    // 进入服务商主页
    toProvider(id) {
      this.$router.push({
        path: "/serviceListHome",
        query: {
          id: id,
        },
      });
    },
    tui_chu() {
      sessionStorage.clear();
      localStorage.clear();
      this.$router.push({
        path: "/login",
      });
    },
    login() {
      this.$router.push({
        path: "/login",
      });
    },
    toClick() {
      let routeData = this.$router.resolve({
        path: "/member1",
      });
      window.open(routeData.href, "_blank");
    },
    dian_index() {
      this.$router.push({
        path: "/home",
      });
    },

    TrackClick() {
      //点击添加收藏
      let url = window.location;
      let title = document.title;
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("360se") > -1) {
        alert("由于360浏览器功能限制，请按 Ctrl+D 手动收藏！");
      } else if (ua.indexOf("msie 8") > -1) {
        window.external.AddToFavoritesBar(url, title); //IE8
      } else if (document.all) {
        //IE类浏览器
        try {
          window.external.addFavorite(url, title);
        } catch (e) {
          alert("您的浏览器不支持,请按 Ctrl+D 手动收藏!");
        }
      } else if (window.sidebar) {
        //firfox等浏览器；
        window.sidebar.addPanel(title, url, "");
      } else {
        alert("您的浏览器不支持,请按 Ctrl+D 手动收藏!");
      }
    },
    handleClick() {
      //点击搜索
      if (this.new_title === "") {
        alert("请输入关键词");
      } else {
        window.scrollTo(0, 672);
        if (this.$route.path === "/homess") {
          this.$bus.$emit("titleClick", this.new_title);
        } else {
          this.$router.push({
            path: "/homess",
            query: {
              title: this.new_title,
            },
          });
        }
        this.new_title = "";
      }
    },
  },
};
</script>

<style scoped lang="less">
.bod_colo {
  color: #bbbbbb;
}
.nav-bar {
  width: 100%;
  background-color: #f9f9ff;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  .nav-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;
    width: 1400px;
    margin: 0 auto;
    background-color: #f9f9ff;
    color: #696969;
    font-size: 14px;
    span {
      margin-left: 9px;
    }
    .top-ri {
      display: flex;
      height: 42px;
      line-height: 42px;
      .ri-le {
        display: flex;
        cursor: pointer;
        position: relative;
        img {
          width: 14px;
          height: 14px;
        }
        p {
          margin-left: 9px;
          margin-right: 12px;
        }
        .identity {
          position: absolute;
          top: 30px;
          left: 0px;
          background-color: #eee;
          width: 150%;
          line-height: 30px;
          z-index: 99;
          // border: 1px solid #eee;
        }
      }
      .ri-mid {
        display: flex;
        cursor: pointer;
        img {
          width: 11px;
          height: 14px;
        }
        p {
          margin-left: 9px;
          margin-right: 12px;
        }
      }
      .ri-ri {
        display: flex;
        cursor: pointer;
        img {
          width: 16px;
          height: 14px;
        }
        p {
          margin-left: 9px;
        }
      }
      .ri-p {
        width: 2px;
        height: 16px;
        margin-right: 20px;
        background-color: #fff;
      }
    }
  }
  .nav-bot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 130px;
    padding: 0 14%;
    background-color: #f9efde;
    .bot-le {
      display: flex;
      align-items: center;
      .le-le {
        width: 90px;
        height: 90px;
      }
      .le-mid {
        width: 180px;
        height: 42px;
        margin-left: 4px;
      }
      .le-ri {
        width: 205px;
        height: 44px;
        margin-left: 24px;
      }
    }
    .bot-ri {
      display: flex;
      align-items: center;
      .ri-le {
        display: flex;
        align-items: center;
        width: 200px;
        height: 36px;
        background-color: #fff;
        img {
          width: 21px;
          height: 21px;
          margin-left: 11px;
        }
        input {
          width: 145px;
          margin-left: 8px;
          margin-bottom: 1px;
          font-size: 16px;
        }
        input::-webkit-input-placeholder {
          color: #e5e5e5;
          font-size: 14px;
        }
      }
      .ri-ri {
        width: 85px;
        height: 36px;
        margin-left: 4px;
        border-radius: 5px;
        color: #fff;
        font-size: 14px;
        text-align: center;
        line-height: 36px;
        background-color: #8f0000;
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
	#app{
		overflow: hidden;
	}
	.nav-bar .nav-top{
		
		flex-wrap: wrap;
	}
	.nav-bar .nav-top {
		overflow: hidden;
		height: 8rem;
		width: 98%;
		overflow-x: hidden;
	}
	.nav-bar .nav-top span{
		font-size: 1.6rem;
	}
	.nav-bar .nav-top .top-ri .ri-mid p{
		font-size: 1.6rem;
	}
	.nav-bar .nav-top .top-ri .ri-ri p{
		font-size: 1.6rem;
	}
	
}
</style>
