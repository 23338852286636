<template>
	<footer>
		<div class="foottop">
			<div class="banxin">
				<div class="tabarlist">
					<div class="tab" v-for="i in name" :key="i.id" @click="xq(i.id)">
						{{ i.name }}
					</div>
				</div>
			</div>
		</div>
		<div class="banxin">
			<div class="footcen">
				<div class="foleft">
					<div class="fltop">
						<div class="fltitle">
							全国免费服务热线
						</div>
						<div class="flhua">
							0818-3333218
						</div>
						<div class="flri">
							<div class="ritxt">
								工作日
							</div>
							<div class="qitxt">
								08:30-21:00
							</div>
						</div>
						<div class="flri">
							<div class="ritxt">
								节假日
							</div>
							<div class="qitxt">
								08:30-12:00
							</div>
							<div class="qitxt">
								13:30-17:30
							</div>
						</div>
					</div>

					<div class="beibox">
						<div class="beitxt" @click="toRescue" >
							{{detail.copyright}}<br>{{ detail.miitbeian }}
						</div>
						<div class="beitxt" @click="policeLink">
							网安备案号：川公网安备 51170302000141号
						</div>
					</div>
					<div class="tiaobox">
						<div class="gaobox">
							<a href="https://beian.miit.gov.cn/" target="_blank">
								<img src="../../assets/img/images/foa_03.png" class="gaoli" alt="">
							</a>
							<a href="https://www.12377.cn/" target="_blank">
								<img src="../../assets/img/images/fob_03.png" class="gaoli" alt="">
							</a>
							<a href="http://www.cyberpolice.cn/wfjb/" target="_blank">
								<img src="../../assets/img/images/foc_03.png" class="gaoli" alt="">
							</a>
							<!-- <img src="../../assets/img/images/fod_03.png" class="gaoli" alt="">
							<img src="../../assets/img/images/foe_03.png" class="gaoli" alt=""> -->
							<a href="https://www.samr.gov.cn/" target="_blank">
								<img src="../../assets/img/images/fof_03.png" class="gaoli" alt="">
							</a>
						</div>
						<!-- <img src="../../assets/img/images/fog_06.png" class="tiaopic" alt=""> -->
					</div>
				</div>

				<div class="foright">
					<div class="forli">
						<img src="../../assets/img/images/ma.png" class="forpic" alt="">
						<div class="fortxt">
							关注科汇云
						</div>
					</div>
					<!-- <div class="forli">
						<img src="../../assets/img/images/ma.png" class="forpic" alt="">
						<div class="fortxt">
							关注科汇云
						</div>
					</div> -->
				</div>
			</div>
		</div>
	</footer>
</template>
<script>
	import {
		Logo,
		Bottom
	} from '../../common/js/api';
	import '../../common/js/cnzz';
	import {
		urlImg
	} from '../../common/js/url';
	export default {
		name: 'TabBar',
		data() {
			return {
				tabaList: [{
						name: '关于科汇',
						nav_url: '/BottonXq'
					},
					{
						name: '科汇大事记',
						nav_url: '/BottonXq'
					},
					{
						name: '诚聘英才',
						nav_url: '/government'
					},
					{
						name: '领导关怀',
						nav_url: '/wisdom'
					},
					{
						name: '联系我们',
						nav_url: '/enterprise'
					}
				],
				url: 'https://beian.miit.gov.cn/',
				url1: 'http://www.beian.gov.cn/',
				name: {},
				detail:{}
			};
		},
		created() {
			this.getBottom();
			this.getLogo();
		},
		mounted() {
			setTimeout(function() {
				cnzz_box.append(cnzz_stat_icon_1280650682);
				cnzz_stat_icon_1280650682.style.cssText = 'opacity: 1;';
			}, 500);
		},

		methods: {
			policeLink() {
						 // window.location.href = this.url1;
						 window.open(this.url1, '_blank');
					},
			toRescue() {
						window.open(this.url, '_blank');
						//  window.location.href = this.url;
					},
			getLogo() {
						Logo({})
							.then(res => {
								this.detail = res.data;
							})
							.catch(() => {});
					},
			goToExternalLink() {
			    window.location.href = "http://www.beian.gov.cn/";
			  },
			tabClick(item, index) {
				//点击
				sessionStorage.setItem('cur', this.currentIndex);
				this.$router.push({
					path: item.nav_url
				});
			},
			// 底部数据
			getBottom() {
				Bottom({})
					.then(res => {
						console.log('22222222222',res);
						this.name = res.data;
						// console.log(this.name);
					})
					.catch(err => {});
			},
			//   前往详情
			xq(id) {
				for (let i = 0; i < this.name.length; i++) {
					if (this.name[i].id == id) {
						console.log(i);
						if (i > 4) {
							let routeData = this.$router.resolve({
								path: '/b_xq',
								query: {
									id: id
								}
							});
							window.open(routeData.href, '_blank');
						} else {
							let routeData = this.$router.resolve({
								path: '/BottonXq',
								query: {
									id: id
								}
							});
							window.open(routeData.href, '_blank');
						}
					}
				}
			},
		},
		filters: {

		}
	};
</script>

<style scoped lang="less">
	footer {
		overflow: hidden;
		width: 100%;
		height: 303px;
		background: url('../../assets/img/images/footbg_02.png')no-repeat center;
		background-size: 100% 303px;
	}

	.banxin {
		width: 1400px;
		margin: 0 auto;
	}

	.foottop {
		border-bottom: 1px solid #48658a;

		.tabarlist {
			display: flex;
			align-items: center;
			padding: 28px 20px;

			.tab:last-child {
				border: none;
				width: 115px;
				text-align: right;
			}

			.tab:first-child {
				width: 105px;
				text-align: left;
			}

			.tab {
				color: #ffffff;
				flex-shrink: 0;
				width: 190px;
				border-right: 1px solid #768aa4;
				text-align: center;
				cursor: pointer;
			}
		}
	}

	.footcen {
		padding: 25px 20px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.foleft {
			flex-grow: 1;

			.fltop {
				display: flex;
				align-items: center;
				padding-bottom: 30px;

				.fltitle {
					font-size: 18px;
					color: #dddddd;
				}

				.flhua {
					font-size: 24px;
					color: #ff7200;
					font-weight: bold;
					padding-left: 12px;
					padding-right: 70px;
				}

				.flri {
					display: flex;
					align-items: center;
					margin-right: 15px;
					border: 1px solid #0c4690;
					height: 25px;

					.ritxt {
						background-color: #0c4690;
						text-align: center;
						color: #ffffff;
						width: 63px;
						height: 100%;
						line-height: 23px;
						font-size: 14px;
						color: #ffffff
					}

					.qitxt {
						font-size: 14px;
						color: #dddddd;
						line-height: 23px;
						padding: 0 12px;
					}
				}
			}

			.beibox {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				padding-bottom: 18px;
				max-width: 900px;

				.beitxt {
					font-size: 14px;
					    color: #dddddd;
					    cursor: pointer;
					    padding: 0 50px 0px 0;
					    line-height: 25px;
				}
			}

			.tiaobox {
				display: flex;
				align-items: center;

				.gaobox {
					display: flex;
					align-items: center;

					.gaoli {
						width: 100px;
						height: 40px;
						flex-shrink: 0;
						margin-right: 15px;
					}
				}

				.tiaopic {
					width: 35px;
					height: 40px;
				}
			}
		}

		.foright {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.forli {
				margin-left: 40px;
				width: 125px;
				height: 155px;
				background-color: #0c438a;
				text-align: center;

				.forpic {
					margin: 11px auto 13px;
					width: 105px;
					height: 105px;
				}

				.fortxt {
					font-size: 14px;
					color: #dddddd;
				}
			}
		}
	}

	@media screen and (max-width: 1200px) {
		.foottop .tabarlist {
			display: none;
		}

		.footcen .foleft .fltop {
			flex-wrap: wrap;
		}

		.footcen .foleft .fltop .fltitle {
			font-size: 2rem;
			padding-bottom: 1.5rem;
		}

		.footcen .foleft .fltop .flhua {
			font-size: 2rem;
			padding-bottom: 1.5rem;
			width: 50%;
		}

		.footcen .foleft .fltop .flri {
			height: 3rem;
		}

		.footcen .foleft .fltop .flri .ritxt {
			width: auto;
			padding: 0 1rem;
			line-height: 3rem;
			font-size: 1.4rem;
		}

		.footcen .foleft .fltop .flri .qitxt {
			font-size: 1.4rem;
		}

		footer {
			height: 28rem;
			background-size: 100% 28rem;
		}

		.footcen .foleft .beibox .beitxt {
			font-size: 1.5rem;
			padding-bottom: 1rem;
			line-height: 1.8rem;
		}

		.footcen .foleft .tiaobox .gaobox .gaoli {
			height: auto;
			width: 10rem;
			margin-bottom: 1rem;
		}

		.footcen .foleft .tiaobox .gaobox {
			flex-wrap: wrap;
		}

		.footcen .foleft .tiaobox {
			flex-wrap: wrap;
		}

		.footcen .foleft .tiaobox .tiaopic {
			display: none;
		}
	}
</style>