import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './assets/css/reset.css'
// import './assets/css/public.css'

import 'animate.css'


import VueAMap from 'vue-amap'; // 高德地图
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
	// key: AMapKey,
	key: '58691a783f2ee5676dc230da2e225990',
	plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar',
		'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation', 'AMap.DistrictSearch'
	],
	v: '1.4.4',
	uiVersion: '1.0.11', // 版本号
})

import axios from 'axios'
Vue.prototype.$axios = axios


import dayjs from "dayjs"
Vue.prototype.dayjs = dayjs; //可以全局使用dayjs

// import '../public/rem'
//全局注册swiper

// import 'lib-flexible'

import Video from 'video.js'
import 'video.js/dist/video-js.css'

Vue.config.productionTip = false

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

// import style
// import 'swiper/css/swiper.css'
import 'swiper/dist/css/swiper.min.css'
// import 'swiper/dist/js/swiper.min'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */ )

import socket from './common/js/socket'
Vue.prototype.socket = socket

import VDistpicker from 'v-distpicker'
Vue.component('v-distpicker', VDistpicker)

import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'

Vue.use(VueDirectiveImagePreviewer, {
	background: { // or : background: '#000'
		color: '#000' // or rgba or rgb     // or image: 'url(xxx)'
	},
	// transition
	animate: {
		duration: 600,
		delay: 500
	},
	// loading (not supported)
	loading: {
		image: ''
	},
	// cursor(css)
	cursor: 'pointer',
	clickMethod: 'doubleClick' // click or doubleClick(not supported)
})

//注册element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import echarts from 'echarts'
Vue.prototype.$echarts = echarts;

Vue.prototype.$bus = new Vue();

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
