import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import('views/home/Home.vue')
// const Needs = () => import('views/need/need.vue')
const Needs = () => import('views/special/Docking.vue')
const Needjc = () => import('views/need/Needjc.vue')
const NeedFb = () => import('views/need/Needfb.vue')
const Needcgal = () => import('views/need/Needcgal.vue')
const organizati = () => import('views/organizati/organizati.vue')
const Sucessresult = () => import('views/organizati/Sucessresult.vue')
const gaoxiao = () => import('views/organizati/gaoxiao.vue')
const GaoDetail = () => import('views/organizati/GaoDetai.vue')
const consult = () => import('views/organizati/consult.vue')
const Keyanys = () => import('views/organizati/Keyanys.vue')
const humancloud = () => import('views/humancloud/humancloud.vue')
const scientist = () => import('views/humancloud/scientist.vue')
const scientistdtail = () => import('views/humancloud/scientistdtail.vue')
const position = () => import('views/humancloud/position.vue')
const scientistrz = () => import('views/humancloud/scientistrz.vue')
const Recruit = () => import('views/humancloud/Recruit.vue')
const positiondetail = () => import('views/humancloud/positiondetail.vue')
const Unitdetails = () => import('views/humancloud/Unitdetails.vue')
const apply = () => import('views/humancloud/apply.vue')
const chuanggj = () => import('views/chuangxin/chuanggj.vue')
const kehuixy = () => import('views/chuangxin/kehuixy.vue')
const kechengxq = () => import('views/chuangxin/kechengxq.vue')
const ceshijz = () => import('views/chuangxin/ceshijz.vue')
const qiyecx = () => import('views/chuangxin/qiyecx.vue')
const qiyecxtwo = () => import('views/chuangxin/qiyecxtwo.vue')
const zhengcepp = () => import('views/chuangxin/zhengcepp.vue')

//two
const dongtai = () => import('views/humancloud/dongtai.vue')


// 登录
const Login = () => import('views/login/login.vue')
const MemberService = () => import('views/login/agreement/member.vue')
const TradingService = () => import('views/login/agreement/trading.vue')
const PrivacyProtection = () => import('views/login/agreement/privacy.vue')

const BottonXq = () => import('views/botton/BottonXq.vue')
const Botton = () => import('views/botton/botton.vue')

// 科服云
const Kefuyun = () => import('views/Kefuyun/Kefuyun.vue')
const Dynamic = () => import('views/dynamic/Dynamic.vue')
const Dynamicxq = () => import('views/dynamic/Dynamicxq.vue')
const Service = () => import('views/service/service.vue')
const Manager = () => import('views/service/manager.vue')
const Service_Settled = () => import('views/service/Service_settled.vue')
const Release = () => import('views/service/release.vue')
const Information = () => import('views/service/information.vue')
const S_Details = () => import('views/service/S_Details.vue')
const Zixun = () => import('views/zixun/zixun.vue')
const serviceListHome = () => import('views/service/serviceListHome.vue')

// 智产云
const Zhichanyun = () => import('views/zhichanyun/zhichanyun.vue')
const ZRelease = () => import('views/zhichanyun/release.vue')
const ZReleaseXQ = () => import('views/zhichanyun/xq.vue')
const Patent = () => import('views/zhichanyun/patent.vue')
// 
const Need = () => import('views/demand/need.vue')
const Need_Details = () => import('views/demand/need_details.vue')
// 

// 政府云
const Zhengfuyun = () => import('views/zhengfuyun/zhengfuyun.vue')
const Policy_details = () => import('views/policy/policy.vue')
const Tack = () => import('views/zhengfuyun/tack.vue')
const Tab = () => import('views/zhengfuyun/base/tabXq.vue')
const ServiceCloud = () => import('views/zhengfuyun/base/serviceCloud.vue')
const ServiceCloud1 = () => import('views/zhengfuyun/base/serviceCloud1.vue')
const ServiceCloud2 = () => import('views/zhengfuyun/base/serviceCloud2.vue')
const EnterpriseCloud = () => import('views/zhengfuyun/base/enterpriseCloud.vue')
const EnterpriseCloud1 = () => import('views/zhengfuyun/base/enterpriseCloud1.vue')
const EnterpriseCloud2 = () => import('views/zhengfuyun/base/enterpriseCloud2.vue')

// 企业云
const Qiyeyun = () => import('views/qiyeyun/qiyeyun.vue')
const Expert = () => import('views/special/Expert.vue')
const Hall = () => import('views/special/Hall.vue')
const Docking = () => import('views/special/Docking.vue')
const Achievement = () => import('views/special/Achievement.vue')
const Achievement_details = () => import('views/special/Achievement_details.vue')
const Special = () => import('views/special/Special.vue')
//会员中心
const Member = () => import('views/member/member.vue')
const test1 = () => import('views/page/test1/index.vue')
const test11 = () => import('views/page/test1/test1-1.vue')
const test15 = () => import('views/page/test1/test1-5.vue')

const test2 = () => import('views/page/test2/index.vue')
const test21 = () => import('views/page/test2/test2-1.vue')
const PG = () => import('views/page/test2/pg.vue')
const innovation = () => import('views/page/test2/innovation.vue')

const test3 = () => import('views/page/test3/index.vue')
const test31 = () => import('views/page/test3/test3-1.vue')

const test4 = () => import('views/page/test4/index.vue')
const test41 = () => import('views/page/test4/test4-1.vue')


const test5 = () => import('views/page/test5/index.vue')
const test51 = () => import('views/page/test5/test5-1.vue')
const test52 = () => import('views/page/test5/test5-2.vue')

const test6 = () => import('views/page/test6/index.vue')
const test61 = () => import('views/page/test6/test6-1.vue')

const test7 = () => import('views/page/test7/index.vue')
const test71 = () => import('views/page/test7/test7-1.vue')

const test8 = () => import('views/page/test8/index.vue')
const test81 = () => import('views/page/test8/test8-1.vue')
const XQ = () => import('views/page/test8/xq.vue')
// 设备列表
const device = () => import('views/need/device.vue')
const devicedetail = () => import('views/need/devicedetail.vue')
Vue.use(VueRouter)

const routes = [{
		path: '/',
		redirect: '/home',
		meta: {
			requiresAuth: false,
			keepAlive: true,
		} //显示
	},
	{
		path: '/dynamic',
		component: dongtai,
		meta: {
			requiresAuth: false,
			keepAlive: true,
		} //显示
	},
	{
		path: '/home',
		component: Home,
		meta: {
			requiresAuth: false,
			keepAlive: true,
		} //显示
	},
	{
		path: '/zhengcepp',
		component: zhengcepp,
		meta: {
			requiresAuth: true,
			keepAlive: true
		} //显示
	},
	{
		path: '/qiyecx',
		component: qiyecx,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/qiyecxtwo',
		component: qiyecxtwo,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/ceshijz',
		component: ceshijz,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/courseDetail',
		component: kechengxq,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/kehuixy',
		component: kehuixy,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/innovation',
		component: chuanggj,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/apply',
		component: apply,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},

	{
		path: '/positiondetail',
		component: positiondetail,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/Unitdetails',
		component: Unitdetails,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/Recruit',
		component: Recruit,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},

	{
		path: '/scientistrz',
		component: scientistrz,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/position',
		component: position,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/scientistdtail',
		component: scientistdtail,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/scientist',
		component: scientist,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/humancloud',
		component: humancloud,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/Keyanys',
		component: Keyanys,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/consult',
		component: consult,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/GaoDetail',
		component: GaoDetail,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/gaoxiao',
		component: gaoxiao,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/sucessresult',
		component: Sucessresult,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/organizati',
		component: organizati,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},

	// {
	//     path: '/',
	//     component: Home,
	//     meta: {
	//          keepAlive: true,
	//     } //显示
	// },
	{
		path: '/need',
		component: Needs,
		meta: {
			requiresAuth: true,
			keepAlive: false
	
		} //隐藏
	},
	{
		path: '/device',
		component: device,
		meta: {
			requiresAuth: true,
			keepAlive: false

		} //隐藏
	},
	{
		path: '/devicedetail',
		component: devicedetail,
		meta: {
			requiresAuth: true,
			keepAlive: false
	
		} //隐藏
	},
	{
		path: '/needjc',
		component: Needjc,
		meta: {
			requiresAuth: true,
			keepAlive: false

		} //隐藏
	},
	{
		path: '/needfb',
		component: NeedFb,
		meta: {
			requiresAuth: true,
			keepAlive: false

		} //隐藏
	},
	{
		path: '/needcgal',
		component: Needcgal,
		meta: {
			requiresAuth: true,
			keepAlive: false

		} //隐藏
	},
	{
		path: '/BottonXq',
		component: Botton, //底部数据详情页
		meta: {
			requiresAuth: false,
			keepAlive: true,
		} //隐藏
	},
	{
		path: '/b_xq',
		component: BottonXq, //底部数据详情页
		meta: {
			requiresAuth: false,
			keepAlive: true,
		} //隐藏
	},
	{
		path: '/login',
		component: Login, //登录
		meta: {
			keepAlive: false,
			requiresAuth: false,
		} //隐藏
	},
	{
		path: '/member-service',
		component: MemberService, //科汇云会员服务协议
		meta: {
			keepAlive: false,
			requiresAuth: false,
		} //隐藏
	},
	{
		path: '/trading-service',
		component: TradingService, //科汇云交易服务协议
		meta: {
			keepAlive: false,
			requiresAuth: false,
		} //隐藏
	},
	{
		path: '/privacy-protection',
		component: PrivacyProtection, //隐私保护声明
		meta: {
			keepAlive: false,
			requiresAuth: false,
		} //隐藏
	},
	{
		path: '/about', //科服云  
		component: Kefuyun,
		meta: {
			requiresAuth: false,
			keepAlive: true,
		} //显示
	},
	{
		path: '/manager', //科服云技术经理人详情
		component: Manager,
		meta: {
			requiresAuth: false,
			keepAlive: true,
		} //显示 
	},
	{
		path: '/dynamicS', //科服云动态列表
		component: Dynamic,
		meta: {
			requiresAuth: false,
			keepAlive: true,

		} //显示
	},
	{
		path: '/Dynamicxq', //科服云动态详情
		component: Dynamicxq,
		meta: {
			requiresAuth: false,
			keepAlive: true,

		} //显示
	},
	{
		path: '/service', //服务展示
		component: Service,
		meta: {
			requiresAuth: false,
			keepAlive: true,

		} //显示
	},
	{
		path: '/service_s', //服务商入驻
		component: Service_Settled,
		meta: {
			requiresAuth: true,
			keepAlive: true,

		} //显示
	},
	{
		path: '/s_release', //服务发布
		component: Release,
		meta: {
			requiresAuth: true,
			keepAlive: true,

		} //显示
	},
	{
		path: '/s_info', //服务商信息  ---- 发布
		component: Information,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/s_xq', //服务详情
		component: S_Details,
		meta: {
			requiresAuth: false,
			keepAlive: true

		} //显示
	},
	{
		path: '/chat', //在线咨询
		component: Zixun,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/serviceListHome', //服务商主页
		component: serviceListHome,
		meta: {
			requiresAuth: false,
			keepAlive: true

		} //显示
	},

	{
		path: '/wisdom', //智产云
		component: Zhichanyun,
		meta: {
			keepAlive: true,
			requiresAuth: true,

		} //显示    ZRelease
	},
	{
		path: '/ZRelease', // 发布专利
		component: ZRelease,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示    
	},
	{
		path: '/Patent', // 更多专利
		component: Patent,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示    
	},
	{
		path: '/ZReleaseXQ', // 专利详情
		component: ZReleaseXQ,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示    
	},


	// 
	{
		path: '/more_need', //更多需求
		component: Need,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/need_details', //需求详情
		component: Need_Details,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	// 


	{
		path: '/government', //政府云
		component: Zhengfuyun,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/serviceCloud', //服务监测云
		component: ServiceCloud,
		meta: {
			requiresAuth: true,
			keepAlive: false
		} //显示
	},
	{
		path: '/serviceCloud1', //服务监测云 - 长
		component: ServiceCloud1,
		meta: {
			requiresAuth: true,
			keepAlive: false
		} //显示
	},
	{
		path: '/serviceCloud2', //服务监测云 - 短
		component: ServiceCloud2,
		meta: {
			requiresAuth: true,
			keepAlive: false
		} //显示
	},
	{
		path: '/enterpriseCloud', //企业监测云
		component: EnterpriseCloud,
		meta: {
			requiresAuth: true,
			keepAlive: false
		} //显示
	},
	{
		path: '/enterpriseCloud1', //企业监测云 - 长
		component: EnterpriseCloud1,
		meta: {
			requiresAuth: true,
			keepAlive: false
		} //显示
	},
	{
		path: '/enterpriseCloud2', //企业监测云 - 短
		component: EnterpriseCloud2,
		meta: {
			requiresAuth: true,
			keepAlive: false
		} //显示
	},
	{
		path: '/policy', //政策详情
		component: Policy_details,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/tack', //任务与活动详情
		component: Tack,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/tab', //考核或评估详情
		component: Tab,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/enterprise', //企业云
		component: Qiyeyun,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/expert', //专家详情
		component: Expert,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/hall', //需求大厅
		component: Hall,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示            技术需求对接
	},
	{
		path: '/Docking', //技术需求对接专项
		component: Docking,
		meta: {
			requiresAuth: true,
			keepAlive: false
		} //隐藏
	},
	{
		path: '/achievement', //成果推荐 ---更多成果
		component: Achievement,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/Achievement_details', //成果推荐 ---更多成果
		component: Achievement_details,
		meta: {
			requiresAuth: true,
			keepAlive: true

		} //显示
	},
	{
		path: '/special', //企业云 ---专项 （进入专题页面）
		component: Special,
		meta: {
			requiresAuth: true,
			keepAlive: true
		} //显示
	},
	{
		path: '/member', //会员中心
		component: Member,
		redirect: '/member1/test1-1',
		meta: {
			requiresAuth: true,
			keepAlive: false //隐藏
		},
		children: [{
				path: '/member1', //工作台
				component: test1,
				meta: {
					requiresAuth: true,
					keepAlive: false //隐藏
				},
				redirect: '/member1/test1-1', // 该配置是若点击选择一级菜单时，默认选中并跳转到该一级菜单下的第一个二级菜单
				children: [{
						path: 'test1-1',
						component: test11,
						meta: {

							requiresAuth: true,
							keepAlive: false //隐藏
						},
					},
					{
						path: 'test1-2',
						component: () => import('views/page/test1/test1-2.vue'),
						meta: {
							requiresAuth: true,
							keepAlive: false //隐藏
						},
					},
					{
						path: 'test1-3',
						component: () => import('views/page/test1/test1-3.vue'),
						meta: {
							requiresAuth: true,
							keepAlive: false //隐藏
						},
					},
					{
						path: 'test1-4',
						component: () => import('views/page/test1/test1-4.vue'),
						meta: {
							requiresAuth: true,
							keepAlive: false //隐藏
						},
					},
					{
						path: 'test1-5',
						component: test15,
						meta: {
							requiresAuth: true,
							keepAlive: false //隐藏
						}
					},

				]
			},
			{
				path: '/member2', //创新评估
				component: test2,
				meta: {
					requiresAuth: true,
					keepAlive: false //隐藏
				},
				redirect: '/member2/test2-1', // 该配置是若点击选择父目录时，默认选中该父目录下的子路径页面
				children: [
					// {
					//         path: 'test2',
					//         component: test21,
					//         meta: {
					//             requiresAuth: true,
					//             keepAlive: false //隐藏
					//         },

					//     },
					{
						path: '/member2/test2-1', //
						component: PG,
						meta: {
							requiresAuth: true,
							keepAlive: false //隐藏
						}
					},
					{
						path: '/member2/innovation', //
						component: innovation,
						meta: {
							requiresAuth: true,
							keepAlive: false //隐藏
						}
					},
				]
			},
			{
				path: '/member3', //找技术
				component: test3,
				meta: {
					requiresAuth: true,
					keepAlive: false //隐藏
				},
				redirect: '/member3/test3-1',
				children: [{
						path: 'test3-1',
						component: test31,
						meta: {
							requiresAuth: true,
							keepAlive: false //隐藏
						}
					},

				]
			},
			{
				path: '/member4', //找需求
				component: test4,
				meta: {
					requiresAuth: true,
					keepAlive: false //隐藏
				},
				redirect: '/member4/test4-1',
				children: [{
						path: 'test4-1',
						component: test41,
						meta: {
							requiresAuth: true,
							keepAlive: false //隐藏
						}
					},

				]
			},
			{
				path: '/member5', //找政策
				component: test5,
				meta: {
					requiresAuth: true,
					keepAlive: false //隐藏
				},
				redirect: '/member5/test5-1',
				children: [{
						path: 'test5-1',
						component: test51,
						meta: {
							requiresAuth: true,
							keepAlive: false //隐藏
						}
					},
					{
						path: 'test5-2',
						component: test52,
						meta: {
							requiresAuth: true,
							keepAlive: false //隐藏
						}
					},
				]
			},
			{
				path: '/member6', //找服务
				component: test6,
				meta: {
					requiresAuth: true,
					keepAlive: false //隐藏
				},
				redirect: '/member6/test6-1',
				children: [{
						path: 'test6-1',
						component: test61,
						meta: {
							requiresAuth: true,
							keepAlive: false //隐藏
						}
					},

				]
			},
			{
				path: '/member7', //找人才
				component: test7,
				meta: {
					requiresAuth: true,
					keepAlive: false //隐藏
				},
				redirect: '/member7/test7-1',
				children: [{
						path: 'test7-1',
						component: test71,
						meta: {
							requiresAuth: true,
							keepAlive: false //隐藏
						}
					},

				]
			},
			{
				path: '/member8', //科创活动
				component: test8,
				meta: {
					requiresAuth: true,
					keepAlive: false //隐藏
				},
				redirect: '/member8/test8-1',
				children: [{
						path: 'test8-1',
						component: test81,
						meta: {
							requiresAuth: true,
							keepAlive: false //隐藏XQ
						}
					},
					{
						// path: '/member8/test8-1/XQ', //科创活动详情
						path: '/member8/XQ', //科创活动详情
						component: XQ,
						meta: {
							requiresAuth: true,
							keepAlive: false //隐藏
						}
					},
				]
			},
		]
	},

]

const router = new VueRouter({
	mode: 'hash',
	routes,
	scrollBehavior(to, from, savedPosition) {
		// 解决路由跳转后 会滚动到底部
		if (savedPosition) {
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	}
})

// router.beforeEach((to, from, next) => {
// 	let token = window.localStorage.getItem('token')
// 	if (to.matched.some(record => record.meta.requiresAuth) && (!token || token === null)) {
// 		next({
// 			path: '/login',
// 			query: {
// 				redirect: to.fullPath
// 			}
// 		})
// 	} else {
// 		next()
// 	}
// })
export default router